
import { Component, Prop, Watch } from 'vue-property-decorator';
import { getComponent, getConfigEnv } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/antd.css';
// import setpointModule from '@/store/modules/setpointModule';
import defermentLabelingModule from '@/store/modules/defermentLabelingModule';
// import { CHART_CLICK_EVENT } from '@/lib/constants';
import tasqProductionDataChartModule from '@/store/modules/tasqProductionDataChartModule';
import accountModule from '@/store/modules/accountModule';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import tasqActionsModule from '@/store/modules/tasqActionsModule';
import DateRangePicker from 'vue2-daterange-picker';
import { Debounce } from 'vue-debounce-decorator';
import { SHOW_ENDPOINT_RESPONSE_ALERT } from '@/lib/constants';
import GenericMixin from '@/lib/mixins/GenericMixin';
import DataLoading from '../../lib/mixins/dataLoading';
import TasqJob from '../../interfaces/tasqs/TasqJob';

@Component({
  components: {
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    LineChart: () => import('@/lib/charts/lineChart'),
    DateRangePicker,
    VueSlider,
  },
})
export default class DefermentLabeling extends mixins(DataLoading, GenericMixin) {
  @Prop({ required: true }) tasq!: TasqJob;

  showTrainingDefermentModelProgress = false;

  isLoadingTrainingData = false;

  failToLoadData = false;

  toogleE =false

  isSavingModel = false;

  isPreviewingModel = false;

  fixedAxis = false;

  oilTypeProduction = false;

  gasTypeProduction = false;

  boeTypeProduction = false;

  gasDataIndex = -1;

  oilDataIndex = -1;

  get productionType() {
    return getConfigEnv('PRODUCTION_TYPE');
  }

  comment: any = '';

  mouseLeaveRange() {
    setTimeout(() => {
      this.fixedAxis = true;
    }, 1500);
  }

  customSignalRange: any = { startDate: null, endDate: null };

  yAxisRange: any = [0, 100];

  showWellTargetPopup = false;

  xAxisRange = ['', ''];

  minYaxis: any = 0;

  zoomUpdated = 0;

  maxYaxis: any = 100;

  colorCode: any = '';

  mainRateLabel: any = '';

  defermentType: any = '';

  indexProductionRateType: any = '';

  get selectedCustomRange() {
    if (this.customSignalRange.startDate && this.customSignalRange.endDate) {
      return true;
    }
    return false;
  }

  get dateRangeCustomPreset() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return {
      'Last month': [
        new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ],
      'Last 3 months': [
        new Date(today.getFullYear(), today.getMonth() - 3, today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ],
      'Last 6 months': [
        new Date(today.getFullYear(), today.getMonth() - 6, today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ],
      'Last Year': [
        new Date(today.getFullYear(), today.getMonth() - 12, today.getDate()),
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      ],
    };
  }

  hideRemoveTargetPopup() {
    this.showWellTargetPopup = false;
  }

  async customDateUpdated(data) {
    let fromNowBack = 0;
    const dateNow: any = new Date();
    const date1 = data.startDate;
    const date2 = data.endDate;
    if (Math.ceil(Math.abs(date2)) < Math.ceil(Math.abs(dateNow))) {
      fromNowBack = Math.ceil(Math.abs(date2 - dateNow) / (1000 * 60 * 60 * 24));
    }
    tasqsListModule.setFromNowBack(fromNowBack);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.startDataLoading();
    await defermentLabelingModule.getChartReLabelingData(diffDays);
    await tasqProductionDataChartModule.getProductionDataDefermentLabeling(diffDays);

    await this.sleep(500);
    await this.reloadChart();
    this.stopDataLoading();
  }

  get productionData() {
    return tasqProductionDataChartModule.productionData;
  }

  get productionDataDefermentLabeling() {
    return tasqProductionDataChartModule.productionDataDefermentLabeling;
  }

  productionDataDict: any = {};

  get defermentLabelingData() {
    return defermentLabelingModule.chartLabelingData;
  }

  get updatePredictionData() {
    return defermentLabelingModule.datasetForPrediction;
  }

  get modelUUID() {
    return defermentLabelingModule.ModelUUID;
  }

  previewUpdate() {}

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  formatXaxis(val) {
    return new Date(val).toLocaleString();
  }

  // async animateDefermentLabelProgressBar(seconds) {
  //   if (!seconds) {
  //     // eslint-disable-next-line no-param-reassign
  //     seconds = 60;
  //   }
  //   this.showTrainingDefermentModelProgress = true;
  //   const microseconds = (defermentLabelingModule.defermentModelCalculatingETA + 15) * 100;
  //   // eslint-disable-next-line no-plusplus
  //   for (let x = 1; x < microseconds; x++) {
  //     // @ts-ignore
  //     this.$refs.progressBarID.style.width = `${(
  //       (x / microseconds)
  //       * 100
  //     ).toString()}%`;
  //     // @ts-ignore
  //     this.$refs.progressBarStringID.innerHTML = `Training... ${Math.round(
  //       (x / microseconds) * 100,
  //     ).toString()}%`;

  //     await this.sleep(1);
  //   }
  //   this.showTrainingDefermentModelProgress = false;
  // }

  selectedDates = [] as any;

  addChartDataset(input) {
    const {
 type, label, data, borderColor, backgroundColor = '', labels = [], axis = 'y-axis-0', fill = false,
}: any = input;

    let signalPayload: any = {};
    if (type === 'rate') {
      signalPayload = {
        label,
        data,
        borderColor,
        backgroundColor,
        tension: 0,
        fill,
        pointRadius: 5,
        pointHitRadius: 5,
        pointHoverRadius: 5,
        yAxisID: axis,
        pointBorderColor: borderColor,
        pointBackgroundColor: [] as any,
        borderWidth: 1.5,
        spanGaps: true,
      };
    } else if (type === 'targetBand') {
      signalPayload = {
        label,
        data,
        borderColor,
        backgroundColor,
        tension: 0,
        borderWidth: 2,
        fill,
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        yAxisID: axis,
        spanGaps: true,
        unit: '',
        labels,
      };
    } else if (type === 'target') {
      signalPayload = {
        label,
        data,
        borderColor,
        backgroundColor,
        tension: 0,
        borderWidth: 2,
        fill,
        borderDash: [9, 8],
        pointRadius: 0,
        pointHitRadius: 5,
        pointHoverBorderColor: '#ffffff',
        pointHoverBackgroundColor: '#FFFFFF',
        yAxisID: axis,
        spanGaps: true,
        unit: '',
        labels,
      };
    }

    this.chartData.datasets.push(signalPayload);
  }

  chartData: any = {
    datasets: [],
    labels: [],
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        fontColor: 'rgba(255,255,255,0.7)',
      },
    },
    animation: {
      duration: 500,
    },
    scales: {
      xAxes: [
        {
          type: 'time',
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#ffffff90',
            fontStyle: 'bold',
            maxTicksLimit: 8,
            minRotation: 50,
          },
          time: {
            unit: 'day',
            displayFormats: {
              day: 'MM/DD',
            },
          },
        },
      ],
      yAxes: [
        {
          position: 'left',
          type: 'linear',
          id: 'y-axis-0',
          gridLines: {
            color: 'rgba(255, 255, 255, 0.3)',
          },
          ticks: {
            fontColor: '#ffffff90',
            maxTicksLimit: 2,
            fontStyle: 'bold',
            min: 0,
          },
        },
      ],
    },
    tooltips: {
      enabled: false,
    },
    onClick: (evt) => {
      // eslint-disable-next-line no-underscore-dangle
      const chart = (this.$refs.defermentChart as any).$data._chart;
      const item = chart.getElementAtEvent(evt);
      if (item[0]) {
        // eslint-disable-next-line no-underscore-dangle
        const dateIndex = item[0]._index;
        const date = this.chartData.labels[dateIndex];
        this.toggleDateOnChart(date);
      }
    },
    annotation: {
      annotations: [],
    },
  };

  height = 700;

  async created() {
    this.dataLoading = true;
    if (this.productionType === 'oil') {
      this.oilTypeProduction = true;
      this.colorCode = '44,230,194';
      this.mainRateLabel = 'Oil rate';
      this.defermentType = 'oil_rate';
      this.indexProductionRateType = 'oilRate';
    } else if (this.productionType === 'gas') {
      this.gasTypeProduction = true;
      this.colorCode = '245, 93, 139';
      this.mainRateLabel = 'Gas rate';
      this.defermentType = 'gas_rate';
      this.indexProductionRateType = 'gasRate';
    } else if (this.productionType === 'boe') {
      this.boeTypeProduction = true;
      this.colorCode = '255, 217, 119';
      this.mainRateLabel = 'Boe rate';
      this.defermentType = 'boe_rate';
      this.indexProductionRateType = 'boeRate';
    }
    this.height = (this.viewPortHeightValue / 2 + 100) * 1;
    await this.prepareDefermentLabler();
  }

  async prepareDefermentLabler() {
    this.failToLoadData = false;

    try {
      defermentLabelingModule.setModelUUID(null);

      this.startDataLoading();
      if (!this.productionDataDefermentLabeling.loaded) {
        // await tasqProductionDataChartModule.getProductionData(730);
        await tasqProductionDataChartModule.getProductionDataDefermentLabeling(730);
      }

      this.productionDataDict = tasqProductionDataChartModule.productionDataDefermentLabeling.productionDataDict;

      await this.getChartData();
      await this.sleep(1000);
      this.height = (this.viewPortHeightValue / 2 + 100) * 1;
      await this.reloadChart();
    } catch (error: any) {
      this.failToLoadData = true;
      // this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, { text:
      // 'It is not possible to retrain the model at this moment, please try later', error: true });
      // eslint-disable-next-line no-console
      console.log(error.message);
      this.dataLoading = false;
    }
  }

  async reloadChart() {
    this.gasDataIndex = -1;
    this.oilDataIndex = -1;
    this.startDataLoading();
    this.chartData.datasets = [];
    const productionDataDeferment = this.productionDataDefermentLabeling.data;
    const rateData = this.productionDataDefermentLabeling[this.indexProductionRateType];
    const upperTarget = productionDataDeferment[0];
    const mainTarget = productionDataDeferment[1];
    const lowerTarget = productionDataDeferment[2];

    if (rateData && rateData.length) {
      // set oil rate
      this.addChartDataset({
        type: 'rate',
        data: rateData,
        label: this.mainRateLabel,
        borderColor: `rgba(${this.colorCode})`,
        backgroundColor: `rgba(${this.colorCode},0.1)`,
      });

      // Target upper band
      this.addChartDataset({
        type: 'targetBand',
        data: upperTarget,
        label: 'Target Upper',
        borderColor: `rgba(${this.colorCode},0.1)`,
        backgroundColor: `rgba(${this.colorCode},0.25)`,
      });
      // Target lower band
      this.addChartDataset({
        type: 'targetBand',
        data: lowerTarget,
        label: 'Target Lower',
        borderColor: `rgba(${this.colorCode},0.25)`,
        backgroundColor: `rgba(${this.colorCode},0.25)`,
        fill: '-1',
      });
      // Dashed target band
      this.addChartDataset({
        type: 'target',
        data: mainTarget,
        label: 'Target',
        borderColor: `rgba(${this.colorCode})`,
        backgroundColor: 'transparent',
        fill: true,
      });

      // const targetKey = this.chartData.datasets[0].label.includes('Gas') ? 'upperDataTime' : 'upperDataOilRateTime';
      this.oilDataIndex = 0;
      const mappedDataset = this.defermentLabelingData.dataset
      .filter((i) => i.Include).map((i) => i.date);
      this.chartData.datasets[0].pointBackgroundColor = this.chartData.labels
      .map((i) => (mappedDataset.includes(i) ? `rgba(${this.colorCode})` : 'transparent'));
      if (upperTarget && lowerTarget) {
      const targetKey = 'upperDataTime';
      if (this.productionDataDefermentLabeling[targetKey] && this.productionDataDefermentLabeling[targetKey][0]) {
        const indexForTarget = this.productionDataDefermentLabeling.time.findIndex(
          (t) => t === this.productionDataDefermentLabeling[targetKey][0],
        );
        if (indexForTarget > 10 && this.chartData.datasets[1]) {
          this.chartData.datasets[1].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[1].data);
          this.chartData.datasets[2].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[2].data);
          this.chartData.datasets[3].data = Array(indexForTarget).fill(null).concat(this.chartData.datasets[3].data);
        }
      }
    }
      }

    // @ts-ignore
    // eslint-disable-next-line radix

    if (this.chartData.datasets[0]) {
      this.chartData.labels = this.productionDataDefermentLabeling.time;
      let dataArray = this.chartData.datasets[0].data.map((i) => Number(i));
      if (this.chartData.datasets[3] && this.chartData.datasets[3].data) {
        dataArray = dataArray.concat(this.chartData.datasets[3].data.map((i) => Number(i)));
      }
      const min = Math.min(...dataArray);
      const max = Math.max(...dataArray);

      // eslint-disable-next-line radix
      this.minYaxis = Number(min.toFixed(0));

      // eslint-disable-next-line radix
      this.maxYaxis = Number(max.toFixed(0)) + 300;

      this.yAxisRange = [this.minYaxis, this.maxYaxis];
    }

    const timeData = this.productionDataDefermentLabeling.time;
    if (timeData.length > 2) {
      this.xAxisRange = [timeData[Math.floor(timeData.length / 2)], timeData[timeData.length - 1]];
    }

    this.zoomUpdated += 1;

    this.enableDragging();

    this.stopDataLoading();
  }

  async getChartData() {
    await defermentLabelingModule.getChartReLabelingData();
  }

  async saveDefermentCalc() {
    try {
      this.isSavingModel = true;
      this.$emit('update-prediction-finished', this.comment);

      await defermentLabelingModule.saveFitRun({
        ModelUUID: this.modelUUID,
      });
      this.isSavingModel = false;

      this.$emit('save');
    } catch (error) {
      this.$eventBus.$emit(SHOW_ENDPOINT_RESPONSE_ALERT, {
        text: 'It is not possible to retrain the model at this moment, please try later',
        error: true,
      });
    }
  }

  async removeTarget() {
    // this.isSavingModel = true;
    this.showWellTargetPopup = false;
    this.$emit('target-update', 'Target remove successfully.');
    await defermentLabelingModule.saveFitRunAndRemoveTarget({
      ModelUUID: '',
      nodeID: this.tasq.wellName,
      valueType: this.defermentType,
      nullValue: true,
    });
    tasqProductionDataChartModule.resetProductionDataDefermentLabeling();
    // this.isSavingModel = false;
  }

  async runDefermentCalc() {
    if (this.chartData.datasets.length === 0 || !this.selectedDates.length) {
      return;
    }
    this.isPreviewingModel = true;
    const mappedMasks = this.selectedDates.map((i) => ({
      date: i,
      Include: true,
    }));

    const mappedData: any[] = [];
    // console.log(this.productionDataDict.date);
    if (this.productionDataDict && this.productionDataDict.date) {
      // eslint-disable-next-line block-scoped-var, no-plusplus
      for (let f = 0; f < this.productionDataDict.date.length; f++) {
        mappedData.push({
          date: this.productionDataDict.date[f],
          // eslint-disable-next-line block-scoped-var
          [this.defermentType]: parseFloat(this.productionDataDict[this.defermentType][f]),
        });
      }

      await defermentLabelingModule.getFitRun({
        data: mappedData,
        nodeid: this.tasq.wellName,
        value_type: this.defermentType,
        mask: mappedMasks,
      });
      // await this.sleep(10000)
      while (defermentLabelingModule.fetchingDefermentLabelPrediction) {
        // eslint-disable-next-line no-await-in-loop
        await this.sleep(500);
      }
    }
    this.addNewDefermentLabelsToChart();
    setTimeout(() => {
      this.enableDragging();
    }, 1000);
    this.isPreviewingModel = false;
  }

  async addNewDefermentLabelsToChart() {
    this.startDataLoading();
    await this.sleep(1);
    let fillUpToIndex = 0;
    // eslint-disable-next-line no-plusplus
    for (let y = 0; y < this.chartData.labels.length; y++) {
      if (this.chartData.labels[y] === defermentLabelingModule.newChartLabelingData[0].date) {
        fillUpToIndex = y;
        break;
      }
    }
    const newDataUpperThreshold: any[] = [];
    const newDataLowerThreshold: any[] = [];
    const newDataReferenceData: any[] = [];
    // eslint-disable-next-line no-plusplus
    for (let r = 0; r < fillUpToIndex; r++) {
      newDataUpperThreshold.push(null);
      newDataLowerThreshold.push(null);
      newDataReferenceData.push(null);
    }
    for (
      let x = 0;
      x < defermentLabelingModule.newChartLabelingData.length;
      // eslint-disable-next-line no-plusplus
      x++
    ) {
      newDataReferenceData.push(defermentLabelingModule.newChartLabelingData[x].reference);
      newDataLowerThreshold.push(defermentLabelingModule.newChartLabelingData[x].lower_thresh);
      newDataUpperThreshold.push(defermentLabelingModule.newChartLabelingData[x].upper_thresh);
    }

      const previewColorCode = '169, 244, 255';
      // Target upper band
      this.addChartDataset({
        type: 'targetBand',
        data: newDataUpperThreshold,
        label: 'Preview upper band',
        borderColor: `rgba(${previewColorCode},0.1)`,
        backgroundColor: `rgba(${previewColorCode},0.25)`,
      });
      // Target lower band
      this.addChartDataset({
        type: 'targetBand',
        data: newDataLowerThreshold,
        label: 'Preview lower band',
        borderColor: `rgba(${previewColorCode},0.25)`,
        backgroundColor: `rgba(${previewColorCode},0.25)`,
        fill: '-1',
      });
      // Dashed target band
      this.addChartDataset({
        type: 'target',
        data: newDataReferenceData,
        label: 'Target Preview',
        borderColor: `rgba(${previewColorCode})`,
        backgroundColor: 'transparent',
        fill: true,
      });
    // @ts-ignore
    this.chartData.datasets[6].data = newDataReferenceData;
    // @ts-ignore
    this.chartData.datasets[4].data = newDataUpperThreshold;
    // @ts-ignore
    this.chartData.datasets[5].data = newDataLowerThreshold;

    this.stopDataLoading();
  }

  async updatePrediction() {
    this.startDataLoading();
    const mappedLabels = this.selectedDates.map((i) => ({
      date: i,
      OperatorNodeID: `${getConfigEnv('OPERATOR_PASCALED')}##${this.tasq.wellName}`,
      Include: true,
      key: `${getConfigEnv('OPERATOR_PASCALED')}##${this.tasq.wellName}##${this.tasq.defermentType}`,
      Operator: getConfigEnv('OPERATOR_PASCALED'),
      ModifiedBy: accountModule.user.email,
    }));
    await defermentLabelingModule.updatePrediction({
      mappedLabels,
    });

    this.stopDataLoading();
    this.$emit('update-prediction-finished', this.comment);
    this.comment = '';
  }

  toggleDateOnChart(date, skipRemoving = false) {
    // @ts-ignore
    const dateIndex = this.chartData.labels.indexOf(date);
    // @ts-ignore
    const foundIndex = this.selectedDates.indexOf(date);
    if (foundIndex !== -1 && !skipRemoving) {
      this.selectedDates.splice(foundIndex, 1);
      this.chartData.datasets[0].pointBackgroundColor[dateIndex] = 'transparent';
    } else {
      if (!skipRemoving || foundIndex === -1) {
        // @ts-ignore
        this.selectedDates.push(date);
      }
      this.chartData.datasets[this.oilDataIndex].pointBackgroundColor[dateIndex] = `rgba(${this.colorCode})`;
    }
    // eslint-disable-next-line no-underscore-dangle
    (this.$refs.defermentChart as any).$data._chart.update();
    // this.runDefermentCalc()
  }

  enableDragging() {
    const { defermentChart } = this.$refs;
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    if (defermentChart && defermentChart.$data && defermentChart.$data._chart) {
      // eslint-disable-next-line no-underscore-dangle
      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      const { canvas } = defermentChart.$data._chart;

      // canvas.parentNode.replaceChild(canvas.cloneNode(true), canvas);

      canvas.removeEventListener('pointerdown', null);
      canvas.removeEventListener('pointerup', null);
      canvas.removeEventListener('pointerout', null);

      // @ts-ignore
      // eslint-disable-next-line no-underscore-dangle
      const chart = this.$refs.defermentChart.$data._chart;
      const overlay = document.getElementById('overlay-deferment-labeling');
      if (!overlay) return;
      const { borderColor } = chart?.options?.annotation?.annotations[0] || {};
      let startIndex = -1;
      // @ts-ignore
      overlay.width = this.productionType === 'oilgas' ? window.innerWidth - 150 : window.innerWidth;
      // // @ts-ignore
      // overlay.height = (window.innerHeight / 2) - 100;
      // @ts-ignore
      const selectionContext = overlay.getContext('2d');
      const selectionRect = {
        w: 0,
        startX: 0,
        startY: 0,
      };
      selectionContext.fillStyle = borderColor || 'rgba(255, 255, 255)';
      let drag = false;
      canvas.addEventListener('pointerdown', (evt) => {
        const points = chart.getElementsAtEventForMode(evt, 'index', {
          intersect: false,
        });
        // eslint-disable-next-line no-underscore-dangle
        startIndex = points[0] ? points[0]._index : 0;
        const rect = canvas.getBoundingClientRect();
        selectionRect.startX = evt.clientX - rect.left;
        selectionRect.startY = chart.chartArea.top;
        drag = true;
      });
      canvas.addEventListener('pointermove', (evt) => {
        let rect = canvas.getBoundingClientRect();
        if (drag) {
          rect = canvas.getBoundingClientRect();
          // console.log( evt.clientX - rect.left - selectionRect.startX);
          selectionRect.w = evt.clientX - rect.left - selectionRect.startX;
          selectionContext.globalAlpha = 0.5;
          selectionContext.fillStyle = borderColor;
          selectionContext.clearRect(0, 0, window.innerWidth, window.innerHeight / 2);
          selectionContext.fillRect(
            selectionRect.startX + 21,
            selectionRect.startY,
            selectionRect.w,
            chart.chartArea.bottom - chart.chartArea.top,
          );
        } else {
          selectionContext.clearRect(0, 0, window.innerWidth, window.innerHeight / 2);
          const x = evt.clientX - rect.left;
          if (x > chart.chartArea.left) {
            selectionContext.fillRect(x + 21, chart.chartArea.top, 1, chart.chartArea.bottom - chart.chartArea.top);
          }
        }
      });

      canvas.addEventListener('pointerup', (evt) => {
        const points = chart.getElementsAtEventForMode(evt, 'nearest', {
          intersect: false,
        });

        // Check if any chart element is found
        if (points.length === 0) {
          // Handle the case when no chart element is found
          return;
        }

        drag = false;
        drag = false;
        // console.log(startIndex);
        let start = startIndex;
        if (startIndex === -1) {
          return;
        }
        // console.log(points);
        // eslint-disable-next-line no-underscore-dangle
        let end = points[0]._index;
        // console.log(end);
        if (start > end) {
          end = this.zoomUpdated > 2 ? startIndex : startIndex;
          // console.log(points[0]._index);
          // eslint-disable-next-line no-underscore-dangle
          start = this.zoomUpdated > 2 ? points[0]._index : points[0]._index;
          // console.log(startIndex);
        } else {
          // eslint-disable-next-line no-underscore-dangle
          end = this.zoomUpdated > 3 ? points[0]._index : points[0]._index;
          // console.log(points[0]._index);
          start = this.zoomUpdated > 3 ? startIndex : startIndex;
        }
        if (Math.abs(start - end) >= 1) {
          end += 1;
        }

        const dates = this.chartData.labels.slice(start, end);
        dates.forEach((date) => this.toggleDateOnChart(date, true));
      });
      canvas.addEventListener('pointerout', () => {
        selectionContext.clearRect(0, 0, canvas.width, canvas.height);
      });
    }
  }

  @Watch('gasTypeProduction')
  async onGasProductionTypeUpdate() {
    if (!this.dataLoading) {
      // this.$emit('resetDeferment');
      this.zoomUpdated = 0;
      await this.reloadChart();
    }
  }

  @Watch('oilTypeProduction')
  async onOilProductionTypeUpdate() {
    if (!this.dataLoading) {
      // this.$emit('resetDeferment');
      this.zoomUpdated = 0;
      await this.reloadChart();
    }
  }

  @Debounce(1000)
  @Watch('xAxisRange')
  updatexAxisRangeValue(value) {
    // this.signalVisible = true;
    this.zoomUpdated += 1;
    // console.log(value);
    Object.assign(this.chartOptions.scales.xAxes[0].ticks, {
      max: value[1],
      min: value[0],
    });

    setTimeout(() => {
      this.enableDragging();
    }, 1000);

    // @ts-ignore
  }

  @Debounce(1000)
  @Watch('yAxisRange')
  updateMaxRangeValue(value) {
    // this.signalVisible = true;
    this.zoomUpdated += 1;
    Object.assign(this.chartOptions.scales.yAxes[0].ticks, {
      max: value[1],
      min: value[0],
    });

    setTimeout(() => {
      this.enableDragging();
    }, 1000);
  }
}
